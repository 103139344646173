<style scoped>
  .video-title{
    color: #303030;
    font-size:.5em;
    line-height: 2em;
    margin-bottom:10px;
  }

  .video-description{
    color: #303030;
    font-size:.8em;
  }

  .author-thumb-holder{
    border-radius: 100%
  }

  .author-thumb-holder > .image{
    border-radius: 100%
  }

  .video-buttom-active, .video-buttom-inactive{
    justify-content: center;
    color: #fff;
    display: flex;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
    background: rgb(104 167 43) !important;
  }

  .video-buttom-inactive{
    background: #b9b9b9 !important;
  }
  
  .video-buttom-active > p, .video-buttom-inactive > p{
    padding: 0;
    margin: 0;
  }

  video{
    width: 100%;
  }

  .padding{
    padding:2em;
  }

  .videoHolder{
    position: relative;
  }

  .play-buttom{
    position: absolute;
    left: calc(50% - 12.5px);
    top: calc(40% - 12.5px);
    width: 50px;
    height: 50px;
    border-radius: 25px;
    background: green;
    color: #fff;
    text-align: center;
    line-height: 45px;
    z-index: 2;
  }
</style>
<template>
  <SkeletonScaffold v-bind:padding="this.padding" v-bind:id="this.details.id" borderRadius="10px" v-bind:primary="!showPlayer ? '#fff' : '#81b61b'" animDisable>
    <v-img v-mCardImg v-bind:src="this.details.thumbnail" v-if="!showPlayer"></v-img>

    <div class="videoHolder">
      <p v-if="showPlayer" class="play-buttom" @click="playVideo">
        <v-icon color="#fff" v-if="!this.videoPlaying">mdi-play</v-icon>
      </p>

      <video id="videoPlayer" v-if="showPlayer" controls>
        <source v-bind:src="this.details.videoUrl" type="video/mp4">
      </video>
    </div>

    <div v-bind:class="getDetailClass()" v-if="!showPlayer">
      <div class="video-title">
        <h1>{{this.details.title}}</h1>
      </div> 
      <div class="video-description">
        <p>{{this.details.description}}</p>
      </div>
    </div>

    <div @click="goToDetail()" v-mButton v-bind:class="this.details.videoUrl ? 'video-buttom-active' : 'video-buttom-inactive'" v-if="!showPlayer">
      <p>{{this.details.videoUrl ? 'Assistir' : 'Em breve'}}</p>
    </div>
  </SkeletonScaffold>
</template>
<script>

export default({
  name: 'VideoListItem',
  props: ['details', 'showPlayer', 'padding'],
  data: () => ({
    videoPlaying: false
  }),
  methods:{
    getDetailClass: function(){
      return !this.showPlayer ? "" : "padding";
    },
    goToDetail: function(){
      this.details.videoUrl ? this.$router.replace('/assistir/'+this.details.id) : null;
    },
    playVideo: function(){
      console.log('play video');

      var elem = document.getElementById("videoPlayer");

      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) { 
        elem.msRequestFullscreen();
      }

      elem.play();
    }
  },
  mounted: function(){
    console.log('Page Loaded')
    
    var media = document.getElementById('videoPlayer');

    if(media){
      media.addEventListener("playing", function() {
        this.videoPlaying = true;
        console.log('Video rodando', this.videoPlaying);
      });

      media.addEventListener("pause", function() { 
        this.videoPlaying = false;
        console.log('Video pausado', this.videoPlaying);
      });
    }
  }
})
</script>