<style scoped>
  header{
    background-color: #fff !important;
  }
</style>
<template>
  <v-app>
    <v-app-bar
      app
      color="light"
      light
      v-if="renderTheme()"
      background="#FFF"
    >
      <v-row align="center" >
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

        <CompanyLogo />
        
      </v-row>
    </v-app-bar>
    
    <v-navigation-drawer
      v-model="drawer"
      fixed
      dark
      temporary
      v-if="renderTheme()"
    >
      <v-list-item>
        <v-list-item-avatar>
          <v-img v-bind:src="currentUser.photoURL"></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>{{currentUser.displayName}}</v-list-item-title>
          <v-list-item-subtitle>Usuário</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item
          to="/"
          link
        >
          <v-list-item-icon>
            <v-icon>mdi-play-box-multiple</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Conteúdo Disponível</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        
        <v-list-item
          to="/contato"
          link
        >
          <v-list-item-icon>
            <v-icon>mdi-email</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Fale Conosco</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          @click="logout"
        >
          <v-list-item-icon>
            <v-icon>mdi-exit-to-app</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Sair</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>  

    <v-main  class="main-background">
      
      <router-view></router-view>

    </v-main>
  </v-app>
</template>

<script>
import firebase from 'firebase';
import CompanyLogo from './components/CompanyLogo';

export default {
  name: 'App',

  components: {
    CompanyLogo
  },

  data: () => ({
    currentUser: {
      displayName: '',
      email: '',
      photo: ''
    },
    drawer: false,
    toggleMini: false
  }),
   
  methods: {
    renderTheme: function(){
      return this.$router.currentRoute.meta.renderTheme;
    },
    logout: function(){
      let router = this.$router;

      firebase.auth().signOut().then(()=>{
        router.replace('/login');
      })
    },
    getCurrentUser: function(){
      if(firebase.auth().currentUser && firebase.auth().currentUser.providerData.length > 0){
        return firebase.auth().currentUser.providerData[0];
      }else if(firebase.auth().currentUser){
        return firebase.auth().currentUser;
      }else{
        return false;
      }
    }
  },
  mounted: function(){
    this.currentUser = this.getCurrentUser();
  }

};
</script>
