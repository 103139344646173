<style scoped>
  .form-holder{
    margin-top:40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid #771958;
    padding-top: 40px;
  }

  .form-title{
    text-align: center;
    color: #fff;
  }

  .form-mail{
    display: flex;
    flex-direction:column; 
    width: 80vw;
    background:#fff;
    padding:20px;
    border-radius:10px
  }

  .form-mail > input, .form-mail > textarea{
    border-radius: 5px;
    border:1px solid #ddd;
    margin: 0 0 15px 0;
    padding:5px 10px;
  }

  .form-mail > p{
    padding:0;
    margin: 0;
  }

  .form-bottom{
    background: green;
    border-radius: 5px;
    padding: 5px 10px;
    margin-top:20px
  }
</style>
<template>
    <div class="form-holder">
        <p class="form-title" v-if="this.feedback">
            <strong>O que achou do conteúdo?</strong><br>
            Conte pra gente!
        </p>

      <p class="form-title" v-if="!this.feedback">
        <strong>Precisa falar com a gente?</strong><br>
        Preencha o formulário abaixo e mande sua mensagem.
      </p>

      <div class="form-mail">
        <p for="name">Seu Nome</p>
        <input v-model="form.name" type="text" name="name" placeholder="Nome">
        
        <p for="email">Seu E-mail</p>
        <input v-model="form.email" type="email" name="email" placeholder="E-mail">

        <p for="telefone">Telefone com DDD</p>
        <input v-model="form.phone" type="phone"  name="telefone" placeholder="Telefone com DDD">

        <p for="mensagem">Sua mensagem</p>
        <textarea v-model="form.message" name="mensagem" placeholder="Sua mensagem"></textarea>

        <v-btn class="white--text" text-color="white" color="green" type="submit" @click="sendEmail" v-bind:loading="this.form.loading" >
          Enviar
        </v-btn>
      </div>
    </div>
</template>
<script>
    import firebase from 'firebase';
    import {send, init} from 'emailjs-com';

    export default({
        name: 'FormContact',
        props: ['feedback'],
        components: {
        },
        data: () => ({
            currentUser: {},
            loading: true,
            videos: [],
            form: {
                name: '',
                email: '',
                phone: '',
                message: '',
                loading: false
            }
        }),
        mounted: function(){
            this.currentUser = this.getCurrentUser();
        },
        methods: {
            sendEmail() {
                this.form.loading = true;

                try{
                    init("user_GXPzxUp2WJmKnkHatx4t9");
                
                    var templateParams = {
                        from_name: this.form.name,
                        from_email: this.form.email,
                        from_phone: this.form.phone,
                        user_name: this.currentUser.displayName,
                        user_email: this.currentUser.email,
                        message: this.form.message
                    };

                    console.log('Form data', templateParams);

                    send('service_lf4xxdn', 'template_c5pyqz3', templateParams).then(() =>{
                        alert('Seu e-mail foi enviado com sucesso.');
                        this.form.loading = false;
                        this.form.name = '';
                        this.form.email = '';
                        this.form.phone = '';
                        this.form.message = '';
                    });
                }catch(error) {
                    this.form.loading = false;
                    console.log({error})
                }
            },
            getCurrentUser: function(){
                if(firebase.auth().currentUser && firebase.auth().currentUser.providerData.length > 0){
                    return firebase.auth().currentUser.providerData[0];
                }else if(firebase.auth().currentUser){
                    return firebase.auth().currentUser;
                }else{
                    return false;
                }
            },
        }
    })
</script>
