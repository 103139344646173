<style scoped>
  .video-count{
    font-size: 1em;
    padding: 0;
    font-weight: lighter;
    margin-bottom: 0;
  }

  .wellcome-title{
    font-size: 1.2em;
    padding: 0;
    margin: 10px 0 0 15px;
    color: #fff;
  }

  .wellcome-description{
    font-size: .8em;
    padding: 0;
    margin: 0 0 0 15px;
    color: #fff;
    font-weight: lighter;
  }

  .project-title-holder{
    margin: 60px
  }

  .project-title{
    font-size: 2.5em;
    padding: 0;
    margin: 10px 0 0 15px;
    text-align: center;
    background: -webkit-linear-gradient(45deg, #9bff26, #ffffff 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .project-description{
    padding: 0;
    margin-left: 15px;
    text-align: center;
    color: #ffb6e5;
    font-weight: 300;
    font-size: 1em;
  }

  .text-white{
    color: #fff;
  }

  .video-list{
    padding:20px 0px;
    display: flex;
    flex-wrap: wrap;
  }

  .video-item-desktop{
    flex-grow: 1;
    width: 25%;
    max-width: 25%;
    padding: 0 10px;
    margin-bottom:20px;
  }

  .video-item-mobile{
    flex-grow: 1;
    width: 100%;
    padding: 0 10px;
    margin-bottom:20px;
  }
</style>

<template>
  <v-container>
    <div class="project-title-holder">
      <h1 class="project-title">Projeto de assistência motivacional</h1>
      <h2 class="project-description">É Barueri, cuidando de quem cuida e educa nossos jovens e crianças!</h2>
    </div>

    <div style="display:flex;flex-direction:row">
      <div style="display: flex;flex-direction: column;flex: 1;">
        <p class="wellcome-title">Seja bem vindo(a), {{getCurrentUser().displayName}}</p>
        <p class="wellcome-description">Aqui você encontrará todo o conteúdo disponível</p>
      </div>
      <div style="flex: 1;display: flex;align-items: flex-end;justify-content: flex-end;padding-right:15px">
        <p class="video-count text-white">Conteúdo disponível: {{getVideoCount()}}</p>
      </div>
    </div>

    <div class="video-list" v-if="loading">
      <div v-bind:class="videoItemClass()">
        <VideoListItemEmpty/>
      </div>
    </div>

    <div class="video-list" v-if="!loading"> 
      <div v-for="video in videos" :key="video.id" v-bind:class="videoItemClass()">
        <VideoListItem v-bind:padding="'2em'" v-bind:details="video" />
      </div>
    </div>

    <FormContact/>

  </v-container>
</template>

<script>
  import firebase from 'firebase';
  import VideoListItem from '../components/VideoListItem';
  import VideoListItemEmpty from '../components/VideoListItemEmpty';
  import FormContact from '../components/FormContact.vue';

  export default {
    name: 'VideoList',
    components: {
      VideoListItem,
      VideoListItemEmpty,
      FormContact
    },
    data: () => ({
      loading: true,
      currentUser: {},
      videos: []
    }),
    methods: {
      getVideoCount() {
        let count = 0;

        this.videos.map((video)=>{
          if(video.videoUrl && video.videoUrl.length > 4){
            count++;
          }
        });

        return count;

      },
      getCurrentUser: function(){
        if(firebase.auth().currentUser && firebase.auth().currentUser.providerData.length > 0){
          return firebase.auth().currentUser.providerData[0];
        }else if(firebase.auth().currentUser){
          return firebase.auth().currentUser;
        }else{
          return false;
        }
      },
      videoItemClass: function(){
        switch (this.$vuetify.breakpoint.name) {
          case 'xs':
            return 'video-item-mobile'
          case 'sm':
            return 'video-item-mobile'
          case 'md':
            return 'video-item-desktop'
          case 'lg':
            return 'video-item-desktop'
          case 'xl':
            return 'video-item-desktop'
          default:
            return 'video-item-desktop'
        }
      }
    },
    mounted: function(){
      this.currentUser = this.getCurrentUser();

      let self = this;
      var db = firebase.firestore();

      db.collection("videos").get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          self.videos.push({
            id: doc.id,
            title: doc.data().title,
            description: doc.data().description,
            thumbnail: doc.data().thumbnail,
            videoUrl: doc.data().videoUrl,
            likes: doc.data().likes,
          });
        });

        self.loading = false;
      });
    }
  }
</script>
