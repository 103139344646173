
<template>
   <v-app id="inspire">
        <start-nav-bar />

        <v-main class="main-background">
            <v-container  fluid fill-height>

                <v-layout flex-column align-center justify-center>
                    <h1 class="login-title">Seja bem vindo (a), profissional da educação de Barueri!</h1>
                    <p class="login-title">Entre com sua CONTA GOOGLE para ter acesso ao conteúdo.</p>

                    <v-flex class="login-holder">
                        <v-card class="elevation-12">
                            <v-toolbar dark color="#6caa2a">
                                <v-toolbar-title>Acesse sua conta</v-toolbar-title>
                            </v-toolbar>
                            <v-card-text>
                                <v-form>
                                    <v-text-field
                                        prepend-icon="person"
                                        name="login"
                                        label="Email"
                                        type="text"
                                        v-model="email"
                                    ></v-text-field>
                                    <v-text-field
                                        id="password"
                                        prepend-icon="lock"
                                        name="password"
                                        label="Senha"
                                        type="password"
                                        v-model="password"
                                    ></v-text-field>
                                </v-form>
                            </v-card-text>
                            <v-card-actions class="pb-4" style="display:flex;flex-direction:column">
                                <v-spacer></v-spacer>
                                <v-btn color="primary" v-on:click="login">Entrar</v-btn>
                                <br/>
                                <p>Educador de Barueri, clique na opção abaixo.</p>
                                <div style="justify-items: center;display: flex;flex-direction: column;">
                                    <v-btn color="orange" style="margin-bottom:10px" @click="registerWithGoogle">
                                        <v-icon color="#f44336" style="margin-right:10px;" left>mdi-google</v-icon> Com Google
                                    </v-btn>
                                    <!--
                                    <v-btn color="primary" style="margin-bottom:10px" @click="registerWithFacebook">
                                        <v-icon style="margin-right:10px;" left>mdi-facebook</v-icon> Com Facebook
                                    </v-btn>
                                    -->
                                </div>
                            </v-card-actions>
                        </v-card>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-main>
   </v-app>
</template>

<script>
import firebase from 'firebase';
import StartNavBar from '../components/StartNavBar.vue';

export default {
    components: { StartNavBar },
    name: 'Login',
    props: {
        source: String,
    },
    data: () => ({
        email: '',
        password: ''
    }),
    watch: {
        email: function () {
        },
        password: function () {
        }
    },
    methods: {
        getCurrentUser: function(){
            if(firebase.auth().currentUser && firebase.auth().currentUser.providerData.length > 0){
                return firebase.auth().currentUser.providerData[0];
            }else if(firebase.auth().currentUser){
                return firebase.auth().currentUser;
            }else{
                return false;
            }
        },
        parse: function(type){
            return typeof type == 'string' ? JSON.parse(type) : type;
        },
        isValidEmail: function(email){
            
            if(email.indexOf('marcelo.tdsjr@gmail.com') >= 0){
                return true
            }

            if(email.indexOf('c410apadumi@gmail.com') >= 0){
                return true
            }

            if(email.indexOf('@educbarueri.sp.gov.br') >= 0){
                return true
            }

            if(email.indexOf('@professor.barueri.br') >= 0){
                return true
            }

            return false; 

        },  
        login: function(){
            firebase.auth().signInWithEmailAndPassword(this.email, this.password).then(
                () => {
                    window.location.href = '/';
                },
                (err) => {
                    console.log('Falha de login', err.message);
                    alert('Faça login com sua conta Google.');
                }
            );
        },
        registerWithGoogle: function(){
            var provider = new firebase.auth.GoogleAuthProvider();

            firebase.auth()
            .signInWithPopup(provider)
            .then(
                () => {
                    let user = this.getCurrentUser();

                    if(!this.isValidEmail(user.email)){
                        firebase.auth().signOut().then(()=>{
                            alert('Você não tem autorização para acessar.');
                        })
                    }else{
                        window.location.href = '/';
                    }
                },
                (err) => {
                    if(err.code == 'auth/email-already-in-use'){
                        alert('Este email já está sendo utilizado por outro usuário.');
                    }else{
                        alert('Login não realizado.');
                    }
                }
            );
        },
        registerWithFacebook: function(){
            var provider = new firebase.auth.FacebookAuthProvider();

            firebase.auth()
            .signInWithPopup(provider)
            .then(
                () => {
                    var currentUser = firebase.auth().currentUser;

                    currentUser.updateProfile({
                        displayName: this.name,
                        photoURL: "https://example.com/user/profile.jpg"
                    })
                    .then(function() {
                        window.location.href = '/';
                    });
                },
                (err) => {
                    if(err.code == 'auth/email-already-in-use'){
                        alert('Este email já está sendo utilizado por outro usuário.');
                    }else{
                        alert('Não foi possível criar sua conta, '+err.message);
                    }
                }
            );

        }
    }
};
</script>

<style>

    .login-title{
        color: #fff;
        padding: 0;
        margin: 0;
    }

    .login-holder{
        width: 400px;
    }

    .main-background{
        background: rgb(101,37,79);
        background: -moz-linear-gradient(133deg, rgba(101,37,79,1) 0%, rgba(172,45,130,1) 46%, rgba(255,122,211,1) 100%);
        background: -webkit-linear-gradient(133deg, rgba(101,37,79,1) 0%, rgba(172,45,130,1) 46%, rgba(255,122,211,1) 100%);
        background: linear-gradient(133deg, rgba(101,37,79,1) 0%, rgba(172,45,130,1) 46%, rgba(255,122,211,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#65254f",endColorstr="#ff7ad3",GradientType=1);
    }
</style>
