<style scoped>
  .video-buttom{
    justify-content: center;
    color: #fff;
    display: flex;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
    background: rgb(104 167 43) !important;
  }
  
  .video-buttom > p{
    padding: 0;
    margin: 0;
  }
</style>
<template>
  <SkeletonScaffold padding="2em" borderRadius="10px" primary="#fff" >
    <div v-mCardImg></div> 

    <div v-mBox v-mDark style="height: 1.5em"></div> 
    <br/>

    <div v-mBox v-mDark  v-mRandomWidth style="height: 1.5em"></div>
    <br/>

    <div v-mButton class="video-buttom"></div>
  </SkeletonScaffold>
</template>
<script>

export default({
    name: 'VideoListItemEmpty',
    data: () => ({
    }),
})
</script>
