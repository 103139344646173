import Vue from 'vue'
import Router from 'vue-router'
import Login from './views/Login'
import Registro from './views/Registro'
import VideoList from './views/VideoList'
import VideoDetail from './views/VideoDetail'
import Configuracoes from './views/Configuracoes'
import Contato from './views/Contato';

Vue.use(Router);

export default new Router({
    routes: [
        {
            name: 'home',
            path: '/',
            component: VideoList,
            meta: {
                requiresAuth: true,
                renderTheme: true
            }
        },
        {
            name: 'contato',
            path: '/contato',
            component: Contato,
            meta: {
                requiresAuth: true,
                renderTheme: true
            }
        },
        { 
            name: 'login', 
            path: '/login', 
            component: Login, 
            meta: { 
                requiresAuth: false,
                renderTheme: false 
            } 
        },
        { 
            name: 'registro', 
            path: '/registro', 
            component: Registro, 
            meta: { 
                requiresAuth: false,
                renderTheme: false 
            } 
        },
        { 
            name: 'assistir', 
            path: '/assistir/:videoGuid', 
            component: VideoDetail, 
            meta: { 
                requiresAuth: true,
                renderTheme: true 
            } 
        },
        { 
            name: 'configuracoes', 
            path: '/configuracoes', 
            component: Configuracoes, 
            meta: { 
                requiresAuth: true,
                renderTheme: true 
            } 
        },
    ]
})