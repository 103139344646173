<template>
   <v-app id="inspire">
        <start-nav-bar />

        <v-main>
            <v-container fluid fill-height>
                <v-layout justify-center>
                <v-flex xs12 sm8 md4>
                    <v-card class="elevation-12">
                        <v-toolbar dark color="primary">
                            <v-toolbar-title>Crie sua conta</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text>
                            <v-form>
                                <v-text-field
                                    prepend-icon="person"
                                    name="login"
                                    label="Nome"
                                    type="text"
                                    v-model="name"
                                ></v-text-field>
                                <v-text-field
                                    prepend-icon="email"
                                    name="login"
                                    label="E-mail"
                                    type="text"
                                    v-model="email"
                                ></v-text-field>
                                <v-text-field
                                    id="password"
                                    prepend-icon="lock"
                                    name="password"
                                    label="Senha"
                                    type="password"
                                    v-model="password"
                                ></v-text-field>
                            </v-form>
                        </v-card-text>
                        <v-card-actions class="pb-4" style="display:flex;flex-direction:column">
                            <v-btn color="primary" v-on:click="registerWithEmail">Cadastrar</v-btn>
                            <br/>
                            <p>ou entre com</p>
                            <div style="justify-items: center;display: flex;flex-direction: column;">
                                <v-btn color="orange" style="margin-bottom:10px" @click="registerWithGoogle">
                                    <v-icon style="margin-right:10px;" left>mdi-google</v-icon> Com Google
                                </v-btn>
                                <!--
                                <v-btn color="primary" style="margin-bottom:10px" @click="registerWithFacebook">
                                    <v-icon style="margin-right:10px;" left>mdi-facebook</v-icon> Com Facebook
                                </v-btn>
                                -->
                            </div>
                        </v-card-actions>
                    </v-card>
                </v-flex>
                </v-layout>
            </v-container>
        </v-main>
   </v-app>
</template>

<script>
import StartNavBar from '../components/StartNavBar.vue';
import firebase from 'firebase';

export default {
    components: { StartNavBar },
    name: 'Registro',
    props: {
        source: String,
    },
    data: () => ({
        name: '',
        email: '',
        password: ''
    }),
    watch: {
        email: function () {
        },
        password: function () {
        }
    },
    methods: {
        parse: function(type){
            return typeof type == 'string' ? JSON.parse(type) : type;
        },
        registerWithEmail: function(){
            let router = this.$router;

            firebase.auth().createUserWithEmailAndPassword(this.email, this.password).then(
                () => {
                    var currentUser = firebase.auth().currentUser;

                    currentUser.updateProfile({
                        displayName: this.name,
                        photoURL: "https://example.com/user/profile.jpg"
                    })
                    .then(function() {
                        router.replace('/');
                    });
                },
                (err) => {
                    if(err.code == 'auth/email-already-in-use'){
                        alert('Este email já está sendo utilizado por outro usuário.');
                    }else{
                        alert('Não foi possível criar sua conta, '+err.message);
                    }
                }
            );
        },
        registerWithGoogle: function(){
            let router = this.$router;
            var provider = new firebase.auth.GoogleAuthProvider();

            firebase.auth()
            .signInWithPopup(provider)
            .then(
                () => {
                    router.replace('/');
                },
                (err) => {
                    if(err.code == 'auth/email-already-in-use'){
                        alert('Este email já está sendo utilizado por outro usuário.');
                    }else{
                        alert('Não foi possível criar sua conta, '+err.message);
                    }
                }
            );
        },
        registerWithFacebook: function(){
            let router = this.$router;
            var provider = new firebase.auth.FacebookAuthProvider();

            firebase.auth()
            .signInWithPopup(provider)
            .then(
                () => {
                    var currentUser = firebase.auth().currentUser;

                    currentUser.updateProfile({
                        displayName: this.name,
                    })
                    .then(function() {
                        router.replace('/');
                    });
                },
                (err) => {
                    if(err.code == 'auth/email-already-in-use'){
                        alert('Este email já está sendo utilizado por outro usuário.');
                    }else{
                        alert('Não foi possível criar sua conta, '+err.message);
                    }
                }
            );

        }
    }
};
</script>

<style>
</style>
