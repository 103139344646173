<style scoped>
  header{
    background-color: #fff !important;
  }
</style>

<template>
    <v-app-bar
        app
        color="light"
        light
        background="#FFF"
        >
        <v-row align="center" >
            <CompanyLogo />
            <v-spacer></v-spacer>
            <div class="mr-4">
                <v-btn
                    dark
                    small
                    class="ml-4"
                    color="orange"
                    @click="goToLogin"
                    v-if="getRouterName() == 'registro'"
                    >
                    Entrar
                </v-btn>
                <!--
                <v-btn
                    dark
                    small
                    class="ml-4"
                    color="blue"
                    @click="goToRegister"
                    v-if="getRouterName() == 'login'"
                    >
                    Cadastro
                </v-btn>
                -->
            </div>
        </v-row>
    </v-app-bar>
</template>
<script>
import CompanyLogo from './CompanyLogo'

export default({
    name: 'StartNavBar',
    components: {
        CompanyLogo
    },
    data: () => ({

    }),
    methods: {
        getRouterName: function(){
            return this.$router.currentRoute.name;
        },
        goToLogin: function(){
            window.location.href = '/#/login';
        },
        goToRegister: function(){
            window.location.href = '/#/registro';
        }
    }
})
</script>
