<template>
    <p>Configurações</p>
</template>

<script>

  export default {
    name: 'Configuracoes',
    data: () => ({
    }),
  }
</script>
