<style scoped>
    .btn-voltar{
        color: #fff;
        font-weight: bold;
        border-radius: 10px;
        background: #303030;
        padding: 5px 20px;
        align-self: center;
        margin-top: 20px;
    }

    .contato-holder{
        display: flex;
        flex-direction: column;
    }
</style>

<template>
    <v-container class="contato-holder">
        <FormContact />

        <a @click="goToMain()" class="btn-voltar">
            Voltar
        </a>
    </v-container>
</template>

<script>
  import FormContact from '../components/FormContact.vue';

  export default {
    name: 'Contato',
    components: {
        FormContact
    },
    data: () => ({
        
    }),
    methods: {
        goToMain: function(){
          this.$router.replace('/')
        }
    },
    mounted: function(){

    }
  }
</script>
