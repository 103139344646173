<style scoped>
  h1{
    padding-top: 20px;
  }

  h1, h4{
    flex-grow: 1;
    width: 80%;
    max-width: 80%;
    margin: 0;
  }

  h4{
    font-weight: normal;
    margin-bottom: 30px;
  }

  .btn-voltar{
    color:#fff;
    font-weight: bold;
    border-radius:10px;
    background: #303030;
    padding: 5px 20px;
  }

  .text-white{
    color: #fff;
  }

  .video-list{
    padding:20px 0px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
  }

  .video-item{
    flex-grow: 1;
    width: 80%;
    max-width: 80%;
    margin-bottom:20px;
  }

  .video-item > skeleton-card{
    padding: 0;
  }

</style>

<template>
  <v-container class="video-container">
    

    <div class="video-list" v-if="loading">
      <VideoListItemEmpty class="video-item" />
    </div>

    <div class="video-list" v-if="!loading"> 
      <h1 class="text-white">{{this.video.title}}</h1>
      <h4 class="text-white">{{this.video.description}}</h4>

      <VideoListItem v-bind:padding="'0'" class="video-item" v-bind:details="video" v-bind:showPlayer="true"/>

      <a @click="goToMain()" class="btn-voltar">
        Voltar
      </a>

      <FormContact feedback="true"/>
    </div>
  </v-container>
</template>

<script>
  import firebase from 'firebase';
  import VideoListItem from '../components/VideoListItem';
  import VideoListItemEmpty from '../components/VideoListItemEmpty';
  import FormContact from '../components/FormContact.vue';

  export default {
    name: 'VideoDetail',
    components: {
      VideoListItem,
      VideoListItemEmpty,
      FormContact
    },
    data: () => ({
      loading: true,
      video: {}
    }),
    methods: {
        goToMain: function(){
          this.$router.replace('/')
        }
    },
    mounted: function(){
      var self = this;
      var db = firebase.firestore();

      db.collection("videos").where(firebase.firestore.FieldPath.documentId(), '==', this.$route.params.videoGuid).get().then((querySnapshot) => {
        if(!querySnapshot.empty){
          let doc = querySnapshot.docs[0];

          self.video = {
            id: doc.id,
            title: doc.data().title,
            description: doc.data().description,
            thumbnail: doc.data().thumbnail,
            videoUrl: doc.data().videoUrl,
            likes: doc.data().likes,
          };

        }else{
          self.video = false;
        }

        self.loading = false;
      });
    }
  }
</script>
