import Vue from 'vue'
import Home from './App.vue'
import vuetify from './plugins/vuetify';
import Vuex from 'vuex'
import 'es6-promise/auto'
import router from './router'
import SkeletonCards from 'vue-ultimate-skeleton-cards'
import VueYouTubeEmbed from 'vue-youtube-embed'
import firebase from 'firebase';

const firebaseConfig = {
  apiKey: "AIzaSyC_MEf0w2IDiJCOYv9BcfknXXaKLMoii0Y",
  authDomain: "motivaeducacional-b068c.firebaseapp.com",
  projectId: "motivaeducacional-b068c",
  storageBucket: "motivaeducacional-b068c.appspot.com",
  messagingSenderId: "817409496832",
  appId: "1:817409496832:web:6826f5f2438b1e9483c5e5",
  measurementId: "G-0KT3G1VFCK"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

Vue.config.productionTip = false

Vue.use(Vuex)
Vue.use(SkeletonCards)
Vue.use(VueYouTubeEmbed)

router.beforeEach((to, from, next)=> {

  firebase.analytics().logEvent('page_view', { type: 'internal' });

  let currentUser = false;

  if(firebase.auth().currentUser && firebase.auth().currentUser.providerData.length > 0){
    currentUser = firebase.auth().currentUser.providerData[0];
  }else if(firebase.auth().currentUser){
    currentUser = firebase.auth().currentUser;
  }

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  
  if(requiresAuth && !currentUser) next('login');
  else next();

})

let app = '';

firebase.auth().onAuthStateChanged(()=>{
  if(!app){
    app = new Vue({
      vuetify,
      router,
      comments: {router},
      render: h => h(Home)
    }).$mount('#app');
  }
})
